import React from "react";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Layout from "../components/layout";
import emailjs from 'emailjs-com';

import registration from "../images/registration.png";

export default class RegistrationPage extends React.Component {

  state = {
    name: "",
    nameError: "",
    birthdate: "",
    birthdateError: "",
    age: "",
    ageError: "",
    filiation: "",
    filiationError: "",
    email: "",
    emailError: "",
    phone: "",
    phoneError: "",
    address: "",
    addressError: "",
    open: false,
    feedbackMessage: "",
    severity: 'success',
  }

  _calculateAge(birthday) {
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const errorName = `${name}Error`;

    if (name === 'birthdate' && !isNaN((new Date(value)).getTime())) {
      const age = this._calculateAge(new Date(value));
      this.setState({ ["age"]: age });
    }
    this.setState({ [name]: value, [errorName]: value ? '' : 'text-red-500' });
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  }

  sendEmail = (e) => {
    e.preventDefault();

    if (!e.target.name.value || !e.target.email.value || !e.target.phone.value || !e.target.address.value) {
      this.setState({ open: true, feedbackMessage: "Por favor, preencha os dados em falta.", severity: 'warning' });
    } else {
      emailjs.send('jisvicente', 'jisvicente-contact', {
        from_name: e.target.name.value,
        birthdate: e.target.birthdate.value,
        age: e.target.age.value,
        filiation: e.target.filiation.value,
        email: e.target.email.value,
        phone: e.target.phone.value,
        address: e.target.address.value
      }, 'user_Y4AgqsXn5hnRKHzIzXCLG')
      .then(() => {
        this.setState({
          open: true,
          feedbackMessage: "Mensagem enviada com sucesso!",
          severity: 'success',
          name: '',
          birthdate: '',
          age: '',
          filiation: '',
          email: '',
          phone: '',
          address: '',
        });
      }, () => {
        this.setState({ open: true, feedbackMessage: "Por favor, preencha os dados em falta.", severity: 'warning' });
      });
      e.target.reset();
    }
  }

  render() {
    return (
      <div>
        <Snackbar open={this.state.open} autoHideDuration={3500} onClose={this.handleClose}>
          <MuiAlert elevation={6} variant="filled" onClose={this.handleClose} severity={this.state.severity}>
            {this.state.feedbackMessage}
          </MuiAlert>
        </Snackbar>
        <Layout name="registration">
          <div className="text-gray-500 flex flex-col md:flex-row">
            <div className="w-full md:w-2/5 h-1/2 md:min-h-full p-2">
              <div className="flex content-center items-center overflow-hidden">
                <img className="flex-shrink-0 min-w-full min-h-full" src={registration} />
              </div>
            </div>
            <div className="w-full md:w-3/5 p-2">
              <section className="pr-4">
                <h2 className="text-4xl mb-8 leading-loose">
                  Pré-Inscrição
                </h2>

                <form onSubmit={this.sendEmail}>
                  <div className="flex flex-col md:flex-row md:space-x-4">
                    <div className="w-full md:w-1/2">
                      <label className={`block mb-2 text-xs font-bold uppercase ${this.state.nameError}`} htmlFor="name">Nome *</label>

                      <input className="w-full mb-6 rounded-md border-gray-800"
                            id="name"
                            name="name"
                            placeholder="Nome"
                            type="text"
                            value={this.state.name}
                            onChange={this.handleInputChange} />
                    </div>

                    <div className="w-full md:w-1/4">
                      <label className={`block mb-2 text-xs font-bold uppercase ${this.state.birthdateError}`} htmlFor="birthdate">Data de Nascimento</label>

                      <input className="w-full mb-6 rounded-md border-gray-800"
                            id="birthdate"
                            name="birthdate"
                            placeholder="Data de Nascimento"
                            type="date"
                            value={this.state.birthdate}
                            onChange={this.handleInputChange} />
                    </div>


                    <div className="w-full md:w-1/4">
                      <label className={`block mb-2 text-xs font-bold uppercase ${this.state.ageError}`} htmlFor="age">Idade</label>

                      <input className="w-full mb-6 rounded-md border-gray-800"
                            id="age"
                            name="age"
                            placeholder="Idade"
                            type="text"
                            readOnly
                            value={this.state.age}
                            onChange={this.handleInputChange} />
                    </div>
                  </div>

                  <label className={`block mb-2 text-xs font-bold uppercase ${this.state.filiationError}`} htmlFor="filiation">Filiação</label>

                  <input className="w-full mb-6 rounded-md border-gray-800"
                        id="filiation"
                        name="filiation"
                        placeholder="Filiação"
                        type="text"
                        value={this.state.filiation}
                        onChange={this.handleInputChange} />

                  <div className="flex flex-col md:flex-row md:space-x-4">
                    <div className="w-full md:w-3/5">
                      <label className={`block mb-2 text-xs font-bold uppercase ${this.state.emailError}`} htmlFor="email">Email *</label>

                      <input className="w-full mb-6 rounded-md border-gray-800"
                            id="email"
                            name="email"
                            placeholder="Email"
                            type="email"
                            value={this.state.email}
                            onChange={this.handleInputChange} />

                    </div>

                    <div className="w-full md:w-2/5">
                      <label className={`block mb-2 text-xs font-bold uppercase ${this.state.phoneError}`} htmlFor="phone">Telefone *</label>

                      <input className="w-full mb-6 rounded-md border-gray-800"
                            id="phone"
                            name="phone"
                            placeholder="Telefone"
                            type="text"
                            value={this.state.phone}
                            onChange={this.handleInputChange} />
                    </div>
                  </div>

                      <label className={`block mb-2 text-xs font-bold uppercase ${this.state.addressError}`} htmlFor="address">Morada *</label>
                    <textarea
                      className="w-full mb-6 rounded-md border-gray-800"
                      id="address"
                      name="address"
                      placeholder="Morada"
                      rows="4"
                      value={this.state.address}
                      onChange={this.handleInputChange} />

                    <button className="px-4 py-2 text-sm font-bold text-white bg-gray-700 border-b-4 border-gray-800 rounded hover:border-gray-700 hover:bg-gray-600 disabled:opacity-25">
                      Enviar
                    </button>
                </form>
              </section>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}
